import React from 'react'
import { RedocStandalone } from 'redoc'

const BlobDocs = ({fileName}:{fileName: string}) => {
    const url = "https://cdn.kriya.co/images/" + fileName
    return (
        <RedocStandalone
            specUrl={url}
            onLoaded={error => {
                if (error) {
                    <p>Something went wrong!</p>
                    console.log(error)
                }
            }}
            options={{
                theme: {
                    sidebar: {
                        backgroundColor: "#00283C",
                        textColor: "#FFF5E3",

                    },
                    typography: {
                        fontFamily: "Helvetica Neue, sans-serif",
                        headings: {
                            fontFamily: "Helvetica Neue, sans-serif",
                        }
                    },
                    logo: {
                        maxWidth: "200px",
                        gutter: "20px"
                    }
                }
            }}
        />
    )
}

export default BlobDocs