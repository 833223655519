import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import BlobDocs from "./pages/BlobDocs";
import NotFound from "./pages/NotFound";
import { RedirectPage } from "./pages/RedirectPage";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RedirectPage />} />
        <Route
          path="/marketpay"
          element={<BlobDocs fileName={"Kriya-Payments-api.yaml"} />}
        />
        <Route
          path="/payments"
          element={<BlobDocs fileName={"Kriya-Payments-api.yaml"} />}
        />
        <Route
          path="/onboarding"
          element={<BlobDocs fileName={"Kriya-Onboarding-api.yaml"} />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
